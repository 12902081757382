import { graphql } from 'gatsby'
import * as React from 'react'

import LandingPage, { LandingPageProps } from '../components/pages/LandingPage'
import { candidateI18nHe } from '../language/candidateHe'
import DefaultLayout from '../layouts'

const Index = (props: LandingPageProps) => (
  <DefaultLayout language="he">
    <LandingPage
      i18n={candidateI18nHe}
      {...props}
      otherLanguageUrl="/candidate"
      languagePrefix="/he"
      isClient={false}
    />
  </DefaultLayout>
)

export default Index

export const pageQuery = graphql`
  query {
    ...SiteData
  }
`
