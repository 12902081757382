import { i18nHe } from './he'

export const candidateI18nHe = {
  ...i18nHe,
  tagLine: `אנחנו מוצאים את העבודות הטובות ביותר - בשבילך`,
  subtitle: `"סקילד" היא רשת של מיטב מפתחי הפרילנסרים הטובים בארץ. אנחנו מספקים פרילנסרים יוצאי דופן עם הזדמנויות יוצאות דופן`,
  step1: `עברו את המבחנים והראיונות שלנו`,
  step2: `אנחנו מוצאים לך פרוייקט אצל חברה`,
  step3: `נעזור לכל הצדדים לסגור את החוזה ההוגן ביותר לכולם ויאללה לעבודה`,
  feature1: {
    header: 'נבחרו על ידי מומחים',
    text: `ראיינו מאות מפתחים ועבדנו כפרילנסרים בעצמנו. אנו יודעים מה צריך כדי למצוא את האנשים הנכונים.`,
  },
  feature2: {
    header: 'המוכשרים ביותר',
    text: `כל מפתח "סקילד", עבר בדיקות קפדניות ובדיקות רקע, כדי להבטיח את מקומו איתנו.`,
  },
  feature3: {
    header: 'תתחיל בלי סיכון',
    text: `אנו מציעים תקופת ניסיון בחינם. אם אינך מרוצה מהתוצאות, אתה לא משלם.`,
  },
}
